<template>
    <v-sheet
      class="kiwi-my-practice-page kiwi-mock-test kiwi-bg-white kiwi-test-black pa-0 kiwi-radius-8 overflow-hidden border kiwi-border-light-grey-3"
      variant="flat"
    >
      <v-sheet class="kiwi-mock-test-header py-3 px-4" variant="flat">
        <h1 class="kiwi-fs-20 font-weight-bold">Students</h1>
      </v-sheet>
      <v-divider></v-divider>
      <v-sheet class="kiwi-mock-test-content py-3 px-4">
        <v-row class="justify-space-between align-top">
            <v-col cols="12" md="5" lg="5">
                <v-text-field
                    v-model="filter.search"
                    prepend-inner-icon="mdi-magnify"
                    label="Search"
                    variant="outlined"
                    hide-details
                    single-line
                    class="kiwi-form-input"
                    clearable=""
                    @keyup.enter="searchData"
                    @click:clear="searchData"
                    density="compact"
                ></v-text-field>
            </v-col>
            <v-col cols="12" md="2" lg="2"  xlg="1" class="text-right">
                    <v-btn
                        style="margin: 0 !important;"
                        class="h-100 text-none text-uppercase mt-3 text-body-1 kiwi-bg-primary kiwi-text-white"
                        variant="flat"
                        prepend-icon="mdi mdi-tray-arrow-down"
                        @click="exportData"
                    >
                    Export
                    </v-btn>
            </v-col>
        </v-row>

      </v-sheet>
      <v-divider></v-divider>
      <v-sheet class="kiwi-mock-test-content py-3 px-4">
        <div class="justify-start d-flex flex-wrap">
            <div class="px-2" v-for="(pkg, i) in packages">
                <v-chip variant="outlined" class="align-center pkg-chip" :class="{'active-pkg': pkg.id === filter.current_package}"  :color="pkg.id === filter.current_package ? 'rgba(255, 188, 66, 1)' : 'rgba(219, 225, 224, 1)'" @click="selectPackage(pkg)">
                    <img :src="pkg.image" width="16" class="mr-1" />
                    <span class="kiwi-text-black">{{pkg.name}} ({{ pkg.student_count || 0 }}) </span>
                </v-chip>
            </div>
          <div class="px-4" v-if="filter.current_package">
            <v-chip  @click="resetFilter()" color="red" variant="text">
              <v-icon left class="mr-1">mdi-close</v-icon>
              <span class="text-decoration-underline">Remove Filter</span>
            </v-chip>
          </div>
        </div>
      </v-sheet>
      <v-divider></v-divider>
      <v-data-table-server
            class="inst-list-table"
            v-model:items-per-page="filter.page_size"
            :headers="headers"
            :items="students"
            :items-length="pagination.count || 0"
            :loading="fetching"
            @update:page="serverIndex"
            @update:items-per-page="studentStore.getStudents"
            :items-per-page-options="[10, 15, 20, 30]"
            loading-text=""
            items-per-page-text=""
        >
        <template v-slot:item="{ item, index }">
            <tr>
                <td style="min-width: 110px; white-space: nowrap;">{{ item.user_full_name }}</td>
                <td>{{ item.phone_number }}</td>
                <td>{{ item.email }}</td>
                <td>
                    <span class="flex align-center">
                        <img v-if="item.current_package?.image" :src="item.current_package.image" width="17" />
                        {{ item.current_package ? item.current_package.name : '' }}
                    </span>
                </td>
                <td>
                <v-btn class="kiwi-bg-primary kiwi-text-white" elevation="0"rounded="xl" size="small" style="text-transform: none;" @click="$e => applyVoucherForStd(item)">Apply Voucher</v-btn>
                </td>
                <td>{{ (item.date_joined).slice(0, 10) }}</td>
                <td>{{ getRemaingDays(item.remaining_subscription_days) }}</td>
                <td><v-btn @click="useAsUserAction(item.use_this_as_url)" class="use-user-btn">Use this as User</v-btn></td>
            </tr>
        </template>
        <template v-slot:no-data>
          <div class="no-data-container">
            <img src="/assets/no_data.svg" alt="No data" class="no-data-image" />
          </div>
        </template>
        </v-data-table-server>
    </v-sheet>
    <PromptDialog v-if="prompt" v-bind="prompt" />
    <ApplyVoucher v-if="voucherStudent" @closeModal="()=> voucherStudent = null" :student="voucherStudent" @onSuccess="() => studentStore.getStudents()" />
  </template>
  <script setup>
    import { ref } from 'vue';
    import { useInstituteStudentStore } from '@/stores/institute/institute-student-store';
    import { storeToRefs } from 'pinia';
    import PromptDialog from '@/components/Prompt/PromptDialog.vue';
    import ApplyVoucher from "./Student/ApplyVoucher.vue"
    const prompt = ref(null)
    const studentStore = useInstituteStudentStore();
    const {fetching, students, packages, filter, pagination} = storeToRefs(studentStore)
    filter.value.page = 1;
    filter.value.search = ''
    studentStore.getPackages()
    studentStore.getStudents()
    const itemsPerPage = ref(10)
    const loading = ref(false)
    const voucherStudent = ref(null)
    const selectPackage = (pkg) => {
        if(filter.value.current_package === pkg.id) {
            filter.value.current_package = null
        }else {
            filter.value.current_package = pkg.id
        }
        filter.value.page = 1
        studentStore.getStudents()
    }
    const searchData = () => {
        filter.value.page = 1
        studentStore.getStudents()
    }
    const headers = ref([
        { title: 'Full Name',  key: 'name', sortable: false},
        { title: 'Phone Number', key: 'phone',  sortable: false},
        { title: 'Email Address', key: 'email',sortable: false},
        { title: 'Subscribed Package', key: 'current_package.name',sortable: false },
        { title: '', key: '', },
        { title: 'Account Creation', key: 'date_joined',sortable: false },
        { title: 'Remaining Days', key: 'remaining_subscription_days' ,sortable: false},
        { title: 'Actions', key: 'actions'}
      ])
    const getRemaingDays = (day) => {
        if(day === '') {
            return "-"
        }else if(day === 0) {
            return "Today"
        }else if(day) {
            return `${day} day${day>1 ? 's' : ''}`
        }
        return '-'
    }
    const exportData = async () => {
        prompt.value = { content: 'Exporting..', progress: true, width: 300 }
        try {
            await studentStore.exportData()
            prompt.value = false
        }catch(err) {
            prompt.value = { title: 'Export Failed', content: 'Failed to students data, please try again',
            actions: [
                {
                text: "Close",
                type: "fill",
                onClick: () => prompt.value = null,
                },
            ]
            }
        }
    }
    const applyVoucherForStd = (std) => {
        voucherStudent.value = std
    }
    const serverIndex = (page)=>{
      filter.value.page  = page;
      studentStore.getStudents();
    }
    const resetFilter = () =>{
      filter.value.current_package = null
      studentStore.getStudents();
    }
    const useAsUserAction = (url) => {
        window.open(url, 'newwindow', 'width=1000,height=1000,top=100,left=100');
    }
  </script>
<style lang="scss">
    .v-table.inst-list-table {
        thead {
            tr {
                th {
                    white-space: nowrap;
                    font-size: 0.95rem;
                    background: rgba(242, 244, 246, 1);
                }
            }
        }
        tbody {
            tr {
                td {
                    font-size: 0.95rem;
                }
            }
        }
    }
    .pkg-chip {
        font-weight: 500
    }
    .pkg-chip.active-pkg {
        background: linear-gradient(127.21deg, #FFFFFF 12.55%, #FAF3CE 111.14%);
    }
    .use-user-btn {
        font-size: 14px !important;
        font-weight: 600 !important;
        color: var(--color-black-3);
        background-color: #F1F6FF;
        border: 1px solid #E6E7E7;
        border-radius: 8px;
        text-transform: none;
        box-shadow: none;
    }
</style>
